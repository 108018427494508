@import 'breakpoints';

.contact {
	&__persons {
		margin-top: 2rem;
		text-align: center;

		p {
			margin-bottom: 0;
		}
	}

	&__email {
		display: block;
		margin-bottom: 1rem;
	}
}