@import 'colors';

.button {
	padding: 1rem 1.5rem;
	border-radius: .5rem;
	text-decoration: none;

	&__footer {
		background-color: $primary-blue;
		color: white;
	}
}

a.button {
	display: inline-block;
	cursor: pointer;
}