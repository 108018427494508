@import 'breakpoints';

.text-image {
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;

	@media (min-width: $screen-medium) {
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
	}

	&__text,
	&__image {
		width: 100%;
		padding: 2rem 0;

		@media (min-width: $screen-medium) {
			width: 50%;
			padding: 2rem;
		}
	}

	&__text {
		max-width: $max-width-text;
	}

	&__image img {
		width: 100%;
		height: auto;
	}
}