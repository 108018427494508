@import 'breakpoints';

.single-text {
	margin: 0 auto;
	max-width: $max-width-text;
	padding: 2rem 0;

	@media (min-width: $screen-medium) {
		padding: 2rem;
		text-align: center;
	}
}