@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

@import 'nav';
@import 'colors';
@import 'breakpoints';
@import 'text-image';
@import 'single-image';
@import 'single-text';
@import 'footer';
@import 'button';
@import 'contact';
@import 'about';

body {
	font-family: 'Quicksand', sans-serif;
	margin-bottom: 90px;

	a {
		color: $primary-blue;
	}

	h1, h2, h3, h4, h5 {
		margin-bottom: 1rem;
	}
}

.max-width {
	padding: 0 2rem;
	margin: 0 auto;

	@media (min-width: $screen-large) {
		width: 100%;
		max-width: 1200px;
		padding: 0;
	}
}

.page {
	margin-bottom: 8rem;
}

