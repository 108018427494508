@import 'colors';
@import 'breakpoints';

/* navigation */

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: .5rem 1rem;
	max-width: 1200px;
	margin: 0 auto;

	@media (min-width: $screen-xsmall) {
		padding: .5rem 2rem;
	}

	@media (min-width: $screen-xlarge) {
		padding: .5rem 0;
	}

	.nav {
		&__collapse {
			border-bottom: solid 2px rgba(0, 0, 0, 0.025);

			@media (min-width: $screen-medium) {
				border-bottom: none;
			}
		}
		
		&__logo {
			max-width: 200px;
		}
	}

	svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
		color: $primary-blue;
	}

	.navbar-toggler {
		border: none;
	}
}


.navbar-expand-md .navbar-collapse {
	@media (min-width: $screen-medium) {
		justify-content: flex-end;
	}
}

.navbar-nav {
	align-items: center;
	color: $primary-blue;

	.nav-link {
		width: 100%;
		text-align: center;

		&.active {
			color: $secondary-yellow;
		}
	}
}


