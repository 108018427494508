

#footer {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	margin: 2rem auto;
}

.footer__item {
	cursor: pointer;
}

